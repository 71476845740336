/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dataActions } from "../../../../shared/store/data-Slice";
import { ActivityLogo, Clock, CloseBlack, DrpArr, LinkIcon } from "../../../common/svg/AllSvg";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import { url } from "../../../../shared/utils/url";
import useAxios from "../../../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../common/layout/PageLoader";
import moment from "moment";
// import SecondaryBtn from "../../../common/form/SecondaryBtn";
import Error from "../../../common/form/Error";
import SecondaryBtn from "../../../common/form/SecondaryBtn";

const ComplaintDetail = ({ type }: any) => {
    const { t } = useTranslation('common');
    const complaintDetail: boolean = useSelector((state: any) => state.data.complaintDetail);
    const user: any = useSelector((state: any) => state.auth.user);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)
    const [stFocus, setStFocus] = useState<boolean>(false)
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();
    const menus: any[] = [
        { name: t("grievance.home.banner.status.pending"), key: "Pending_Review" },
        { name: t("grievance.home.banner.status.in_progress"), key: "Pending_Verification" },
        { name: t("grievance.home.banner.status.in_investigation"), key: "Under_Investigation" },
        { name: t("grievance.home.banner.status.resolved"), key: "Resolved" }
    ]

    const statusList: any = { "Pending_Review": t("grievance.home.banner.status.pending"), "Pending_Verification": t("grievance.home.banner.status.in_progress"), "Under_Investigation": t("grievance.home.banner.status.in_investigation"), "Resolved": t("grievance.home.banner.status.resolved"), "Reopen": t("grievance.home.banner.status.reopen") }

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setComplaintDetail(false));
                if (window?.location?.pathname?.includes("resolve")) {
                    !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
                } else {
                    type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/vigilance-complaints`) : navigate(`/vigilance-complaints`))
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setComplaintDetail(false))
            if (window?.location?.pathname?.includes("resolve")) {
                !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
            } else {
                type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/vigilance-complaints`) : navigate(`/vigilance-complaints`))
            }
        }
    };

    const getComplaint = async () => {
        try {
            setLoading(true)
            setIsError(false)
            const res: any = await useAxios.get(`/${(!!lang ? lang : "en") + url?.vigilance_complaint_detail + params?.id}/`);

            if (res && res.status === 200 && res?.data?.length > 0) {
                setData(res?.data?.[0])
                setLoading(false)
            }
        } catch (error) {
            setIsError(true)
            setLoading(false)
            console.error("error while fetching complaint detail", error);
        }
    }

    useEffect(() => {
        (!!lang && !!params?.id) && getComplaint();
    }, [lang, params?.id])

    const handleStatusChange = async (key: any) => {
        try {
            if (key === "Resolved") {
                dispatch(dataActions.setComplaintDetail(false))
                !!params?.lang ? navigate(`/${params?.lang}/resolve-complaint/${params?.id}`) : navigate(`/resolve-complaint/${params?.id}`)
            } else {
                // if (user?.role === "Vigilance") {
                //     const res: any = await useAxios.put(`/${(!!lang ? lang : "en") + url?.vigilance_assign_complaint + data?.id}/`, { status: key, activity_remarks: null });

                //     if (res && res.status === 200) {
                //         dispatch(dataActions.setComplaintApiCall(true));
                //         getComplaint()
                //     }
                // } else {
                dispatch(dataActions.setFeedback({ ...data, status: key }))
                dispatch(dataActions.setAddRemarksComplaint(true))
                dispatch(dataActions.setComplaintDetail(false))
                // }
            }
        } catch (error) {
            console.error("error while status change", error);
        }
    }

    return (
        <>
            {complaintDetail &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] md:w-[700px] msm:w-[930px] min-h-[550px] xlx:min-h-[583px] relative bg-white dark:bg-bgDarkDull rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
                            <div className="w-full flex justify-between gap-[15px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                                <h5 className="text-primaryText dark:text-white font-semibold">{data?.ticket_no}</h5>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => {
                                    dispatch(dataActions.setComplaintDetail(false))
                                    if (window?.location?.pathname?.includes("resolve")) {
                                        !!params?.lang ? navigate(`/${params?.lang}/all-resolve-complaint`) : navigate(`/all-resolve-complaint`)
                                    } else {
                                        type !== "passenger" ? (!!params?.lang ? navigate(`/${params?.lang}`) : navigate(`/`)) : (!!params?.lang ? navigate(`/${params?.lang}/vigilance-complaints`) : navigate(`/vigilance-complaints`))
                                    }
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>
                            {loading ?
                                <PageLoader />
                                : <>
                                    {isError ? <Error /> : <div className="h-full w-full py-[15px] xlx:py-[19px] px-[20px] xlx:px-[24px] flex gap-[15px] xlx:gap-[25px] flex-col xlsm:flex-row">
                                        <div className="w-full xlsm:w-[195px] bg-opacity-[0.2] bg-[#E4E4E4] rounded-[15px] border border-[#A7A7A7] h-full border-opacity-[0.2] flex flex-col pb-[130px] xlx:pb-[140px]">
                                            <h6 className="flex items-center justify-center w-full text-[#313131] dark:text-white font-semibold p-[15px] bg-[#D9D9D9] bg-opacity-[0.3] rounded-t-[15px]">{t("grievance.detail.general_info")}</h6>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.name")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">{data?.created_by_details?.first_name + " " + data?.created_by_details?.last_name}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.date")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all uppercase">{moment(data?.created_at).format('DD MMM YYYY, hh:mm A')}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.status")}</p>
                                                <p className="text-xs !leading-tight flex items-center justify-center whitespace-nowrap h-[22px] px-[11px] text-white font-semibold bg-[#FCAB02] border border-[#EEE] rounded-full">{statusList?.[data?.status]}</p>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("auth.form.field.email")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">{data?.created_by_details?.email}</h6>
                                            </div>

                                            {!!data?.created_by_details?.mobile && <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.phone")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">+91 {data?.created_by_details?.mobile}</h6>
                                            </div>}
                                        </div>

                                        <div className="w-full xlsm:w-[calc(100%-210px)] xlx:w-[calc(100%-220px)] flex flex-col">
                                            <div className="flex items-center justify-between w-full gap-[15px] flex-wrap">
                                                <h6 className="text-blackDull dark:text-textBlackDull font-semibold">{t("grievance.detail.title")}</h6>
                                                {data?.status !== "Resolved" ? <div className={`flex xs:items-center w-full xs:w-fit toggle-submit-btn items-end justify-end rounded-[6px] ${stFocus && "shadow-[0_0_0_3px_#059db25e]"} hover:shadow-[0_0_0_3px_#059db25e]`}>
                                                    <div className="text-white text-sm !leading-snug font-bold h-[30px] w-fit bg-primary rounded-l-[6px] flex items-center justify-center whitespace-nowrap border-r border-white uppercase px-[12px]">{statusList?.[data?.status]}</div>

                                                    <Menu align="end" menuButton={<MenuButton onFocus={() => setStFocus(true)} onBlur={() => setStFocus(false)}>
                                                        <div className="h-[30px] min-w-[35px] flex items-center justify-center rounded-r-[6px] bg-primary">
                                                            <DrpArr />
                                                        </div>
                                                    </MenuButton>}>
                                                        {Array.isArray(menus) && menus?.map((i: any, idx: number) => (
                                                            <React.Fragment key={idx}>
                                                                {i?.key !== data?.status && <MenuItem onClick={() => handleStatusChange(i?.key)}><div className="text-blackDark text-sm !leading-snug font-semibold h-[30px] w-fit rounded-[5px] flex items-center justify-start px-[14px] whitespace-nowrap capitalize cursor-pointer">{i?.name}</div></MenuItem>}
                                                            </React.Fragment>
                                                        ))}
                                                    </Menu>
                                                </div> :
                                                    <>
                                                        {user?.role === "Vigilance" && <SecondaryBtn title={t("grievance.home.banner.status.reopen")} css="!rounded-[6px] px-[20px] font-extrabold uppercase !h-[30px]" onClick={() => handleStatusChange("Reopen")} />}
                                                    </>
                                                }
                                            </div>

                                            <div className="mt-[8px] w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px]">
                                                <div className="flex items-center gap-[12px]">
                                                    <div className="h-[30px] min-w-[30px] flex items-center justify-center rounded-full text-[#015293] text-[12px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-tight uppercase">
                                                        {data?.created_by_details?.first_name?.charAt(0)}{data?.created_by_details?.last_name?.charAt(0)}
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <p className="text-xs leading-snug text-[#8795AB] dark:text-textBlackDull font-medium">{t("grievance.detail.from")}</p>
                                                        <h6 className="text-[#242555] dark:text-white font-semibold">{data?.created_by_details?.first_name + " " + data?.created_by_details?.last_name}</h6>
                                                    </div>
                                                </div>

                                                <h6 className="text-primaryText dark:text-white font-bold mb-[8px] mt-[12px] break-all">{data?.subject}
                                                </h6>

                                                <h6 className="text-blackDull dark:text-textBlackDull font-medium break-all">{data?.description}</h6>

                                                {!!data?.attachment_details?.aws_file_url && <Link to={data?.attachment_details?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] h-[28px] xlx:h-[30px] px-[9px] gap-[6px] flex items-center justify-center font-semibold text-blackDull w-fit mt-[17px] text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                    <LinkIcon className="xlx:w-[10px] xlx:h-[16px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                    {data?.attachment_details?.name}.{data?.attachment_details?.type}
                                                </Link>}
                                            </div>

                                            {data?.ticket_activities?.length > 0 && <>
                                                <h6 className="text-blackDull dark:text-textBlackDull font-semibold mt-[15px] mb-[12px]">{t("grievance.detail.act")}</h6>

                                                <div className="w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px] flex flex-col gap-[15px] xlx:gap-[25px]">
                                                    {Array?.isArray(data?.ticket_activities) && data?.ticket_activities?.map((item: any, index: number) => (
                                                        <div className="flex gap-[12px]" key={index}>
                                                            {!!item?.created_by ? <div className="h-[20px] min-w-[20px] flex items-center justify-center rounded-full text-[#015293] text-[10px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-snug uppercase">
                                                                {item?.created_by_details?.first_name?.charAt(0)}{item?.created_by_details?.last_name?.charAt(0)}
                                                            </div> :
                                                                <ActivityLogo />
                                                            }

                                                            <div className="flex flex-col gap-[6px]">
                                                                {/* create */}
                                                                {item?.remark === "Ticket Created" && <h6 className="text-blackDull dark:text-textBlackDull font-normal">
                                                                    {lang === "en" ?
                                                                        <>
                                                                            <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                            Created Complaint to
                                                                            <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                        </> :
                                                                        lang === "hi" ?
                                                                            <>
                                                                                <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                                ने शिकायत
                                                                                <b> {statusList?.[item?.ticket_status]}</b> अनुभाग में बनाई।
                                                                            </> :
                                                                            <>
                                                                                <b className="capitalize">{item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}  </b>
                                                                                यांनी तक्रार
                                                                                <b> {statusList?.[item?.ticket_status]}</b> विभागात तयार केली.
                                                                            </>
                                                                    }


                                                                </h6>}

                                                                {/* assign to system */}
                                                                {item?.remark === "Complaint Assigned by System" && <h6 className="text-blackDull dark:text-textBlackDull font-normal">{lang === "en" ? <>Complaint Assigned to <b>{item?.assigned_department_details?.name}</b>.</> : lang === "hi" ? <>शिकायत को <b>{item?.assigned_department_details?.name}</b> को सौंपा गया।</> : <>तक्रार <b>{item?.assigned_department_details?.name}</b> कडे सोपवली गेली आहे.</>} </h6>}

                                                                {/* status change */}
                                                                {((item?.remark === "Ticket Status Updated" && item?.ticket_status !== "Resolved") || (data?.ticket_activities?.[index - 1]?.ticket_status !== item?.ticket_status && item?.ticket_status !== "Resolved" && item?.remark !== "Ticket Created" && item?.remark !== "Complaint Assigned by System")) &&
                                                                    <>
                                                                        <h6 className="text-blackDull dark:text-textBlackDull font-normal">
                                                                            {lang === "en" ?
                                                                                <>
                                                                                    <b className="capitalize">{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                    Moved Complaint to
                                                                                    <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                                </> :
                                                                                lang === "hi" ?
                                                                                    <>
                                                                                        <b className="capitalize">{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        ने शिकायत को
                                                                                        <b> {statusList?.[item?.ticket_status]}</b> करने वाले अनुभाग में स्थानांतरित किया।
                                                                                    </> :
                                                                                    <>
                                                                                        <b className="capitalize">{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name} </b>
                                                                                        यांनी तक्रार
                                                                                        <b> {statusList?.[item?.ticket_status]}</b> विभागात हलवली.
                                                                                    </>
                                                                            }
                                                                        </h6>
                                                                        {(!!item?.remark && item?.remark !== "Ticket Status Updated") && <h6 className="text-blackDull dark:text-textBlackDull font-medium break-all">{item?.remark}</h6>}
                                                                    </>
                                                                }

                                                                {/* Resolved */}
                                                                {item?.ticket_status === "Resolved" &&
                                                                    <>
                                                                        <h6 className="text-blackDull dark:text-textBlackDull font-normal"><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> {lang === "en" ? "Marked Complaint Resolved." : lang === "hi" ? "ने शिकायत को समाधान किया हुआ चिह्नित किया।" : "यांनी तक्रार समाधानित म्हणून चिन्हांकित केली."}</h6>
                                                                        {!!item?.remark && <h6 className="text-blackDull dark:text-textBlackDull font-medium break-all">{item?.remark}</h6>}
                                                                    </>}

                                                                {/* assign to user */}
                                                                {(item?.ticket_status !== "Resolved" && item?.remark !== "Ticket Created" && item?.remark !== "Ticket Status Updated" && item?.remark !== "Complaint Assigned by System" && data?.ticket_activities?.[index - 1]?.ticket_status === item?.ticket_status) &&
                                                                    <div className="flex flex-col gap-[6px]">
                                                                        <h6 className="text-blackDull dark:text-textBlackDull font-normal"> {lang === "en" ? <><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> Complaint Assigned to <b>{!!item?.assigned_department_details ? item?.assigned_department_details?.name : ""}</b>.</> : lang === "hi" ? <><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> ने शिकायत <b>{!!item?.assigned_department_details ? item?.assigned_department_details?.name : ""}</b> को सौंपी।</> : <><b>{(item?.assigned_user === item?.created_by) ? item?.assigned_department_details?.name : item?.created_by_details?.first_name + " " + item?.created_by_details?.last_name}</b> यांनी तक्रार <b>{!!item?.assigned_department_details ? item?.assigned_department_details?.name : ""}</b> यांना सोपवली.</>}</h6>
                                                                        <h6 className="text-blackDull dark:text-textBlackDull font-medium">{item?.remark}</h6>
                                                                    </div>
                                                                }

                                                                {!!item?.attachment_details?.aws_file_url && <Link to={item?.attachment_details?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] h-[28px] xlx:h-[30px] px-[9px] gap-[6px] flex items-center justify-center font-semibold text-blackDull w-fit text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                                    <LinkIcon className="xlx:w-[10px] xlx:h-[16px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                                    {item?.attachment_details?.name}.{item?.attachment_details?.type}
                                                                </Link>}

                                                                <p className={`text-xs xlx:text-[0.688rem] leading-snug flex items-center gap-[6px] text-blackDull dark:text-textBlackDull font-semibold ${item?.attachment_details?.aws_file_url ? "mt-[4px]" : ""}`}><Clock className="fill-blackDull dark:fill-textBlackDull" /> {moment(item?.created_at).format('DD MMM YYYY, hh:mm A')}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>}
                                        </div>
                                    </div>}
                                </>}
                        </div>
                    </div>
                </div >}
        </>
    );
};

export default ComplaintDetail;