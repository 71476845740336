/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dataActions } from "../../../../shared/store/data-Slice";
import { Clock, CloseBlack, LinkIcon, OverAllStar } from "../../../common/svg/AllSvg";
import { url } from "../../../../shared/utils/url";
import useAxios from "../../../../shared/hooks/use-axios";
import { useTranslation } from "react-i18next";
import PageLoader from "../../../common/layout/PageLoader";
import moment from "moment";
import Error from "../../../common/form/Error";
import SecondaryBtn from "../../../common/form/SecondaryBtn";
import { Bounce, ToastContainer } from "react-toastify";
import { errors } from "../../../../shared/utils/errors";

const FeedbackDetail = () => {
    const { t } = useTranslation('common');
    const feedbackDetail: boolean = useSelector((state: any) => state.data.feedbackDetail);
    const user: any = useSelector((state: any) => state.auth.user);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const [data, setData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [isError, setIsError] = useState<boolean>(false)
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const ref: any = useRef();

    const statusList: any = { "open": t("grievance.feedbacks.open"), "closed": t("grievance.feedbacks.close") }

    const categoryColor: any = {
        "appreciation": "text-[#05B2A4] bg-[#05B2A4]",
        "suggestion": "text-[#B2AC05] bg-[#B2AC05]",
        "request": "text-secPurple bg-secPurple",
        "enquiry": "text-[#e46568] bg-[#e46568]",
        "cleanliness": "text-secGreen bg-secGreen",
    }

    const categoryList: any[] = [
        {
            "label": t("passenger.home.feedback.category_list.appreciation"),
            "value": "appreciation",
        },
        {
            "label": t("passenger.home.feedback.category_list.suggestion"),
            "value": "suggestion",
        },
        {
            "label": t("passenger.home.feedback.category_list.request"),
            "value": "request",
        },
        {
            "label": t("passenger.home.feedback.category_list.enquiry"),
            "value": "enquiry",
        },
        {
            "label": t("passenger.home.feedback.category_list.cleanliness"),
            "value": "cleanliness",
        }
    ]

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setFeedbackDetail(false));
                !!params?.lang ? navigate(`/${params?.lang}/feedbacks`) : navigate(`/feedbacks`)
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setFeedbackDetail(false));
            !!params?.lang ? navigate(`/${params?.lang}/feedbacks`) : navigate(`/feedbacks`)
        }
    };

    const getFeedback = async () => {
        try {
            setLoading(true)
            setIsError(false)
            const res: any = await useAxios.get(`${url?.feedback_details + params?.id}/`);

            if (res && res.status === 200 && res?.data?.length > 0) {
                setData(res?.data?.[0])
                setLoading(false)
            }
        } catch (error) {
            setIsError(true)
            setLoading(false)
            console.error("error while fetching feedback detail", error);
        }
    }

    useEffect(() => {
        (!!lang && !!params?.id) && getFeedback();
    }, [lang, params?.id])

    const handleStatusChange = async (key: any) => {
        try {
            if (key === "closed") {
                dispatch(dataActions.setFeedbackDetail(false));
                !!params?.lang ? navigate(`/${params?.lang}/close-feedback/${params?.id}`) : navigate(`/close-feedback/${params?.id}`)
            } else {
                const res: any = await useAxios.put(`/${url?.feedback_status_change + data?.id}/`, { status: key, remarks: null });
                if (res && res.status === 200) {
                    dispatch(dataActions.setFeedbackApiCall(true));
                    getFeedback()
                }
            }
        } catch (error) {
            errors(error)
            console.error("error while status change", error);
        }
    }

    return (
        <>
            {feedbackDetail &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full sm:w-[600px] md:w-[700px] msm:w-[930px] min-h-[550px] xlx:min-h-[583px] relative bg-white dark:bg-bgDarkDull rounded-[10px] shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12),0px_14px_64px_-4px_rgba(24,39,75,0.12)]" ref={ref}>
                            <ToastContainer
                                position="top-center"
                                autoClose={5000}
                                hideProgressBar={false}
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="light"
                                transition={Bounce}
                            />
                            <div className="w-full flex justify-between gap-[15px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5] px-[20px] xlx:px-[24px] py-[10px] xlx:py-[11px]">
                                <h5 className="text-primaryText dark:text-white font-semibold">MMRC/FKS/{data?.id}</h5>

                                <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-[25px] h-[25px] rounded-full focus:shadow-[0_0_0_2px_#4145525e] hover:shadow-[0_0_0_2px_#4145525e] dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => {
                                    dispatch(dataActions.setFeedbackDetail(false))
                                    !!params?.lang ? navigate(`/${params?.lang}/feedbacks`) : navigate(`/feedbacks`)
                                }}>
                                    <CloseBlack />
                                </button>
                            </div>
                            {loading ?
                                <PageLoader />
                                : <>
                                    {isError ? <Error /> : <div className="h-full w-full py-[15px] xlx:py-[19px] px-[20px] xlx:px-[24px] flex gap-[15px] xlx:gap-[25px] flex-col xlsm:flex-row">
                                        <div className="w-full xlsm:w-[195px] bg-opacity-[0.2] bg-[#E4E4E4] rounded-[15px] border border-[#A7A7A7] h-full border-opacity-[0.2] flex flex-col pb-[130px] xlx:pb-[140px]">
                                            <h6 className="flex items-center justify-center w-full text-[#313131] dark:text-white font-semibold p-[15px] bg-[#D9D9D9] bg-opacity-[0.3] rounded-t-[15px]">{t("grievance.detail.general_info")}</h6>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.name")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">{data?.first_name + " " + data?.last_name}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.feedbacks.feedback_date")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all uppercase">{moment(data?.created_at).format('DD MMM YYYY, hh:mm A')}</h6>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.feedbacks.feedback_status")}</p>
                                                <p className="text-xs !leading-tight flex items-center justify-center whitespace-nowrap h-[22px] px-[11px] text-white font-semibold bg-[#FCAB02] border border-[#EEE] rounded-full">{statusList?.[data?.status]}</p>
                                            </div>

                                            <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("auth.form.field.email")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">{data?.email}</h6>
                                            </div>

                                            {!!data?.contact_number && <div className="flex flex-col items-start gap-[4px] px-[20px] py-[12px] border-b border-[#DADADA] dark:border-[#454545] border-opacity-[0.5]">
                                                <p className="font-medium text-blackDull dark:text-textBlackDull">{t("grievance.detail.phone")}</p>
                                                <h6 className="text-primaryText dark:text-white font-semibold break-all">+91 {data?.contact_number}</h6>
                                            </div>}
                                        </div>

                                        <div className="w-full xlsm:w-[calc(100%-210px)] xlx:w-[calc(100%-220px)] flex flex-col">
                                            <div className="flex items-center justify-between w-full gap-[15px] flex-wrap">
                                                <h6 className="text-[#313131] dark:text-textBlackDull font-semibold">{t("grievance.feedbacks.feedback_details")}</h6>

                                                {((user?.role === "Client" || user?.role === "Vendor" || user?.role === "Agent" || user?.role === "grievance_supervisor_admin") && data?.status !== "closed") &&
                                                    <SecondaryBtn title={t("grievance.feedbacks.close")} css="!rounded-[6px] px-[20px] font-extrabold uppercase !h-[30px]" onClick={() => handleStatusChange("closed")} />
                                                }
                                            </div>

                                            <div className="mt-[8px] w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px] flex flex-col gap-[15px]">
                                                <div className="w-full flex items-center justify-between gap-[15px]">
                                                    <div className="flex items-center gap-[12px]">
                                                        <div className="h-[30px] min-w-[30px] flex items-center justify-center rounded-full text-[#015293] text-[12px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-tight uppercase">
                                                            {data?.first_name?.charAt(0)}{data?.last_name?.charAt(0)}
                                                        </div>

                                                        <div className="flex flex-col">
                                                            <p className="text-xs leading-snug text-blackDull dark:text-textBlackDull font-medium">{t("grievance.feedbacks.from")}</p>
                                                            <h6 className="text-[#242555] dark:text-white font-semibold">{data?.first_name + " " + data?.last_name}</h6>
                                                        </div>
                                                    </div>
                                                    <h6 className={`h-[20px] flex items-center justify-center rounded-[4px] uppercase font-semibold px-[6px] bg-opacity-[0.15] !leading-tight whitespace-nowrap ${categoryColor?.[data?.category]}`}>
                                                        {categoryList?.find((item: any) => item?.value === data?.category)?.label}
                                                    </h6>
                                                </div>

                                                <div className="bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] p-[16px] flex flex-col gap-[10px]">
                                                    <h6 className="text-[#242555] dark:text-white font-semibold">{data?.category === "cleanliness" ? t("grievance.feedbacks.cleanliness_rating") : t("grievance.feedbacks.overall_rating")}</h6>
                                                    {data?.category === "cleanliness" ? <div className="flex items-center gap-[25px] flex-wrap">
                                                        {!!data?.cleanliness_of_station && <div className="flex flex-col items-center justify-center gap-[4px]">
                                                            <div className="flex gap-[8px] items-center text-primaryText dark:text-white text-base font-bold !leading-tight">{data?.cleanliness_of_station < 3 ? <OverAllStar className="fill-[#D3161F]" /> : (data?.cleanliness_of_station > 2 && data?.cleanliness_of_station < 4) ? <OverAllStar className="fill-[#D3A116]" /> : <OverAllStar className="fill-[#CDD316]" />} {data?.cleanliness_of_station}/5</div>
                                                            <p className="text-blackDark dark:text-white font-semibold text-xs">{t("grievance.feedbacks.st")}</p>
                                                        </div>}

                                                        {!!data?.cleanliness_of_train && <div className="flex flex-col items-center justify-center gap-[4px]">
                                                            <div className="flex gap-[8px] items-center text-primaryText dark:text-white text-base font-bold !leading-tight">{data?.cleanliness_of_train < 3 ? <OverAllStar className="fill-[#D3161F]" /> : (data?.cleanliness_of_train > 2 && data?.cleanliness_of_train < 4) ? <OverAllStar className="fill-[#D3A116]" /> : <OverAllStar className="fill-[#CDD316]" />} {data?.cleanliness_of_train}/5</div>
                                                            <p className="text-blackDark dark:text-white font-semibold text-xs">{t("grievance.feedbacks.train")}</p>
                                                        </div>}

                                                        {!!data?.cleanliness_of_lift && <div className="flex flex-col items-center justify-center gap-[4px]">
                                                            <div className="flex gap-[8px] items-center text-primaryText dark:text-white text-base font-bold !leading-tight">{data?.cleanliness_of_lift < 3 ? <OverAllStar className="fill-[#D3161F]" /> : (data?.cleanliness_of_lift > 2 && data?.cleanliness_of_lift < 4) ? <OverAllStar className="fill-[#D3A116]" /> : <OverAllStar className="fill-[#CDD316]" />} {data?.cleanliness_of_lift}/5</div>
                                                            <p className="text-blackDark dark:text-white font-semibold text-xs">{t("grievance.feedbacks.lift")}</p>
                                                        </div>}

                                                        {!!data?.washroom_facilities_at_station && <div className="flex flex-col items-center justify-center gap-[4px]">
                                                            <div className="flex gap-[8px] items-center text-primaryText dark:text-white text-base font-bold !leading-tight">{data?.washroom_facilities_at_station < 3 ? <OverAllStar className="fill-[#D3161F]" /> : (data?.washroom_facilities_at_station > 2 && data?.washroom_facilities_at_station < 4) ? <OverAllStar className="fill-[#D3A116]" /> : <OverAllStar className="fill-[#CDD316]" />} {data?.washroom_facilities_at_station}/5</div>
                                                            <p className="text-blackDark dark:text-white font-semibold text-xs">{t("grievance.feedbacks.washroom")}</p>
                                                        </div>}

                                                        {!!data?.rating && <div className="flex flex-col items-center justify-center gap-[4px]">
                                                            <div className="flex gap-[8px] items-center text-primaryText dark:text-white text-base font-bold !leading-tight">{data?.rating < 3 ? <OverAllStar className="fill-[#D3161F]" /> : (data?.rating > 2 && data?.rating < 4) ? <OverAllStar className="fill-[#D3A116]" /> : <OverAllStar className="fill-[#CDD316]" />} {data?.rating}/5</div>
                                                            <p className="text-blackDark dark:text-white font-semibold text-xs">{t("grievance.feedbacks.overall")}</p>
                                                        </div>}
                                                    </div> :
                                                        <div className="flex items-center gap-[10px]">
                                                            {Array?.isArray([1, 2, 3, 4, 5]) && [1, 2, 3, 4, 5].map((item: any, index: number) => (
                                                                <div className="min-w-[24px]" key={index}><OverAllStar className={`${(index + 1) <= data?.rating ? "fill-[#CDD316]" : "fill-[#A8A8A8]"}`} /></div>
                                                            ))}
                                                        </div>
                                                    }
                                                </div>

                                                {!!data?.suggestions_for_improving && <div className="bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] p-[16px] flex flex-col gap-[8px]">
                                                    <h6 className="text-[#242555] dark:text-white font-semibold">{t("grievance.feedbacks.suggestion")}</h6>
                                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium break-all">{data?.suggestions_for_improving}</h6>
                                                </div>}

                                                {!!data?.message && <div className="bg-[#D9D9D9] bg-opacity-[0.2] rounded-[10px] p-[16px] flex flex-col gap-[8px]">
                                                    <h6 className="text-[#242555] dark:text-white font-semibold">{t("grievance.feedbacks.message")}</h6>
                                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium break-all">{data?.message}</h6>
                                                </div>}
                                            </div>

                                            {/* activities */}
                                            {data?.feedback_activities?.length > 0 && <>
                                                <h6 className="text-[#313131] dark:text-textBlackDull font-semibold mt-[15px] mb-[12px]">{t("grievance.detail.act")}</h6>

                                                <div className="w-full rounded-[15px] bg-[#D9D9D9] bg-opacity-[0.2] p-[15px] xlx:p-[20px] flex flex-col gap-[15px] xlx:gap-[25px]">
                                                    {Array?.isArray(data?.feedback_activities) && data?.feedback_activities?.map((item: any, index: number) => (
                                                        <div className="flex gap-[12px]" key={index}>
                                                            <div className="h-[20px] min-w-[20px] flex items-center justify-center rounded-full text-[#015293] text-[10px] font-semibold bg-[#F6FD8D] border border-[#42FD00] !leading-snug uppercase">
                                                                {(item?.closed_by_details?.first_name || item?.closed_by_details?.last_name) ? (`${item?.closed_by_details?.first_name?.charAt(0)}${item?.closed_by_details?.last_name?.charAt(0)}`) : (`${data?.first_name?.charAt(0)}${data?.last_name?.charAt(0)}`)}
                                                            </div>
                                                            <div className="flex flex-col gap-[6px]">
                                                                {/* create */}
                                                                {item?.remarks === "Feedback Posted." && <h6 className="text-blackDull dark:text-textBlackDull font-medium">
                                                                    {lang === "en" ?
                                                                        <>
                                                                            <b className="capitalize">{data?.first_name + " " + data?.last_name}  </b>
                                                                            Created Feedback to
                                                                            <b> {statusList?.[item?.ticket_status]}</b> section.
                                                                        </> :
                                                                        lang === "hi" ?
                                                                            <>
                                                                                <b className="capitalize">{data?.first_name + " " + data?.last_name}  </b>
                                                                                ने प्रतिक्रिया
                                                                                <b> {statusList?.[item?.ticket_status]}</b> अनुभाग में बनाई।
                                                                            </> :
                                                                            <>
                                                                                <b className="capitalize">{data?.first_name + " " + data?.last_name}  </b>
                                                                                यांनी अभिप्राय
                                                                                <b> {statusList?.[item?.ticket_status]}</b> विभागात तयार केली.
                                                                            </>
                                                                    }
                                                                </h6>}

                                                                {/* closed */}
                                                                {item?.feedback_status === "closed" &&
                                                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium"><b>{item?.closed_by_details?.first_name + " " + item?.closed_by_details?.last_name}</b> {lang === "en" ? "Marked Feedback Closed." : lang === "hi" ? "ने प्रतिक्रिया बंद कर दिया है।" : "यांनी अभिप्राय बंद केला आहे."}</h6>}

                                                                {(!!item?.closed_reason && item?.feedback_status === "closed") &&
                                                                    <h6 className="text-blackDull dark:text-textBlackDull font-medium break-all">{item?.remarks}</h6>
                                                                }

                                                                {!!item?.attachment_details?.aws_file_url && <Link to={item?.attachment_details?.aws_file_url} target="_blank" className="rounded-[5px] border border-[#BEF7FF] bg-[#E5FFFD] h-[28px] xlx:h-[30px] px-[9px] gap-[6px] flex items-center justify-center font-semibold text-blackDull w-fit text-sm !leading-tight focus:shadow-[0_0_0_2px_#059db25e] hover:shadow-[0_0_0_2px_#059db25e] dark:bg-bgDark dark:border-[#777777] dark:text-textBlackDull break-all">
                                                                    <LinkIcon className="xlx:w-[10px] xlx:h-[16px] w-[9px] h-[15px] fill-blackDull dark:fill-textBlackDull" />
                                                                    {item?.attachment_details?.name}.{item?.attachment_details?.type}
                                                                </Link>}

                                                                <p className={`text-xs xlx:text-[0.688rem] leading-snug flex items-center gap-[6px] text-blackDull dark:text-textBlackDull font-semibold ${item?.attachment_details?.aws_file_url ? "mt-[4px]" : ""}`}><Clock className="fill-blackDull dark:fill-textBlackDull" /> {moment(item?.created_at).format('DD MMM YYYY, hh:mm A')}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </>}
                                        </div>
                                    </div>}
                                </>}
                        </div>
                    </div>
                </div >}
        </>
    );
};

export default FeedbackDetail;