/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import NewComplaint from "../../../components/corporate/grievance/Home/NewComplaint";
import ComplaintDetail from "../../../components/corporate/grievance/Home/ComplaintDetail";
import ResolveComplaint from "../../../components/corporate/grievance/Home/ResolveComplaint";
import AssignComplaint from "../../../components/corporate/grievance/Home/AssignComplaint";
import CommonFooter from "../../../components/common/layout/CommonFooter";
import ReferBackComplaint from "../../../components/corporate/grievance/Home/ReferBackComplaint";
import FeedbackDetail from "../../../components/corporate/grievance/Home/FeedbackDetail";
import CloseFeedback from "../../../components/corporate/grievance/Home/CloseFeedback";
import AddRemarksComplaint from "../../../components/corporate/grievance/Home/AddRemarksComplaint";

const GrievanceLayout = () => {
    const createComplaint: boolean = useSelector((state: any) => state.data.createComplaint);
    const complaintDetail: boolean = useSelector((state: any) => state.data.complaintDetail);
    const feedbackDetail: boolean = useSelector((state: any) => state.data.feedbackDetail);
    const closeFeedback: boolean = useSelector((state: any) => state.data.closeFeedback);
    const resolveComplaint: boolean = useSelector((state: any) => state.data.resolveComplaint);
    const assignComplaint: boolean = useSelector((state: any) => state.data.assignComplaint);
    const referBackComplaint: boolean = useSelector((state: any) => state.data.referBackComplaint);
    const addRemarksComplaint: boolean = useSelector((state: any) => state.data.addRemarksComplaint);
    const location = useLocation();

    useEffect(() => {
        if (createComplaint || complaintDetail || resolveComplaint || assignComplaint || referBackComplaint || feedbackDetail || closeFeedback || addRemarksComplaint) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }, [createComplaint, complaintDetail, resolveComplaint, assignComplaint, referBackComplaint, feedbackDetail, closeFeedback || addRemarksComplaint]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            {createComplaint && <NewComplaint />}
            {complaintDetail && <ComplaintDetail />}
            {feedbackDetail && <FeedbackDetail />}
            {closeFeedback && <CloseFeedback />}
            {resolveComplaint && <ResolveComplaint />}
            {assignComplaint && <AssignComplaint />}
            {referBackComplaint && <ReferBackComplaint />}
            {addRemarksComplaint && <AddRemarksComplaint type="grievance" />}
            <Outlet />
            <CommonFooter type="grievance" />
        </>
    );
};

export default GrievanceLayout;