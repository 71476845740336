/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dataActions } from "../../../shared/store/data-Slice";
import { CloseSec, FareBg, FromSt, ToSt } from "../../common/svg/AllSvg";
import { useTranslation } from "react-i18next";

const FirstLastTime = () => {
    const firstlastTime: boolean = useSelector((state: any) => state.data.firstlastTime);
    const lang: string = useSelector((state: any) => state.ui.lang);
    const theme: string = useSelector((state: any) => state.ui.theme);
    const ref: any = useRef();
    const dispatch = useDispatch();
    const { t } = useTranslation('common');

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                dispatch(dataActions.setFirstlastTimeOpen(false));
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    document.onkeydown = function (evt: any) {
        if (evt.keyCode === 27) {
            dispatch(dataActions.setFirstlastTimeOpen(false))
        }
    };

    return (
        <>
            {firstlastTime &&
                <div className="fixed inset-0 z-[34] top-0 w-full h-full overflow-y-auto bg-[rgba(216,216,216,0.60)] backdrop-blur-[15px]">
                    <div className="flex justify-center items-center min-h-screen">
                        <div className="m-[15px] mm:mx-auto w-full xlsm:w-[500px] xlx:w-[541px] h-full relative" ref={ref}>
                            <div style={{ background: "linear-gradient(90deg, #2F00FF66 -0.55%, #44B0FF66 22.86%, #8B44FF66 48.36%, #FF664466 73.33%, #EBFF7066 99.34%)" }} className="blur-[35px] absolute left-0 right-0 bottom-0 w-full xlsm:w-[505px] h-[532px] rotate-[180deg]"></div>

                            <div className="shadow-[0px_8px_22px_-6px_rgba(24,39,75,0.12) w-full h-full rounded-[20px] xlx:rounded-[25px] z-[1] relative">
                                <div className="w-full relative h-[58px] xlx:h-[66px] z-[2]">
                                    <div className="flex justify-end w-full h-full">
                                        <div className="w-[calc(100%-174px)] xlx:w-[calc(100%-200px)] rounded-t-[20px] xlx:rounded-t-[25px] h-full border-b-0 border-l-0 border border-[#E4E4E7] bg-white dark:bg-bgDarkDull dark:border-[#454545] px-[18px] xlx:px-[25px] flex justify-end xs:justify-between gap-[10px] pt-[15px] xlx:pt-[21px]">
                                            <p className="text-blackDull  dark:text-textBlackDull font-semibold max-w-[252px] xs:block hidden">{t("passenger.home.first_last_time.desc")}</p>

                                            <button aria-label={t("aria_label.close")} title={t("aria_label.close")} className="w-fit h-fit rounded-full focus:shadow-[0_0_0_2px_#D7D8DD] hover:shadow-[0_0_0_2px_#D7D8DD] flex items-center justify-center dark:focus:shadow-[0_0_0_2px_#ffffff5e] dark:hover:shadow-[0_0_0_2px_#ffffff5e]" type="button" onClick={() => dispatch(dataActions.setFirstlastTimeOpen(false))}>
                                                <CloseSec />
                                            </button>
                                        </div>

                                    </div>

                                    {/* curve */}
                                    <div className="absolute left-[-10px] xlx:left-0 bottom-[-17px] xlx:bottom-[-20px]">
                                        <FareBg className="xlx:w-auto xlx:h-auto h-[75px] w-[210px]" />
                                    </div>

                                    <div className="absolute top-0 left-0 bottom-0 py-[12px] w-[174px] xlx:w-[200px] flex items-center justify-center xlx:justify-end pr-[12px] xlx:pr-[15px]">
                                        <div className="h-[35px] xlx:h-[40px] px-[18px] bg-secDarkRed text-white shadow-[0px_42.444px_61.308px_0px_rgba(0,0,0,0.08)] text-lg font-bold rounded-[20px] flex items-center justify-center whitespace-nowrap">{t("passenger.home.first_last_time.title")}</div>
                                    </div>
                                </div>

                                <div className="w-full rounded-[20px] xlx:rounded-[25px] !rounded-tr-none dark:bg-bgDarkDull dark:border-[#454545] bg-white border border-[#E4E4E7] h-[calc(100%-55px)] xlx:h-[calc(100%-64px)] border-t-0 px-[20px] xlx:px-[25px] py-[20px] xlx:py-[28px] relative">

                                    <p className="text-blackDull  dark:text-textBlackDull font-semibold max-w-[252px] xs:hidden block">{t("passenger.home.first_last_time.desc")}</p>

                                    <div className="grid grid-cols-1 xs:grid-cols-2 gap-[12px] w-full h-full mt-[10px] xs:mt-0">
                                        <div style={{ background: "radial-gradient(172% 152.56% at 92.19% -40.78%, #F6FFC0 0%, #FFF 69.22%)" }} className="border border-[#E2E2E2] rounded-[14px] gap-y-[10px] p-[18px] xlx:p-[20px] w-full h-full">
                                            <img src={require("../../../assets/images/first-time.png")} alt="Fist Metro Timing" />

                                            <div className="mt-[20px] flex flex-col">
                                                <p className="uppercase text-blackDull font-semibold text-xs !leading-snug">{t("passenger.home.first_last_time.first_time")}</p>
                                                <h2 className="text-primaryText font-bold">{t("passenger.home.first_last_time.f_time")}</h2>

                                                <p className="text-primaryText font-semibold mt-[4px]">{lang === "en" ? <>08:30AM <span className="text-danger">On Sunday</span></> : lang === "hi" ? <><span className="text-danger">रविवार को</span> सुबह ०८:३०</> : <><span className="text-danger">रविवारी</span> सकाळी ०८:३०</>}</p>

                                                <div className="flex items-center gap-[6px] mt-[18px]">
                                                    <FromSt />
                                                    <p className="text-blackDull font-semibold">{lang === "en" ? "Aarey JVLR" : lang === "hi" ? "आरे जे.वी.एल.आर" : "आरे जे.वी.एल.आर"} </p>
                                                </div>
                                                <div className="flex items-center gap-[6px] mt-[2px]">
                                                    <ToSt />
                                                    <p className="text-blackDull font-semibold">{lang === "en" ? "Bandra-Kurla Complex" : lang === "hi" ? "बांद्रा-कुर्ला कॉम्प्लेक्स" : "वांद्रे-कुर्ला कॉम्प्लेक्स"} </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ background: "radial-gradient(158.78% 142.45% at 98.11% -34.27%, #C0E5FF 0%, #FFF 100%)" }} className="border border-[#E2E2E2] rounded-[14px] gap-y-[10px] p-[18px] xlx:p-[20px] w-full h-full">
                                            <img src={require("../../../assets/images/last-time.png")} alt="Last Metro Timing" />

                                            <div className="mt-[20px] flex flex-col">
                                                <p className="uppercase text-blackDull font-semibold text-xs !leading-snug">{t("passenger.home.first_last_time.last_time")}</p>
                                                <h2 className="text-primaryText font-bold">{t("passenger.home.first_last_time.l_time")}</h2>

                                                <div className="flex items-center gap-[6px] mt-[36px] xlx:mt-[40px]">
                                                    <FromSt />
                                                    <p className="text-blackDull font-semibold">{lang === "en" ? "Aarey JVLR" : lang === "hi" ? "आरे जे.वी.एल.आर" : "आरे जे.वी.एल.आर"} </p>
                                                </div>
                                                <div className="flex items-center gap-[6px] mt-[2px]">
                                                    <ToSt />
                                                    <p className="text-blackDull font-semibold">{lang === "en" ? "Bandra-Kurla Complex" : lang === "hi" ? "बांद्रा-कुर्ला कॉम्प्लेक्स" : "वांद्रे-कुर्ला कॉम्प्लेक्स"} </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ background: 'radial-gradient(182.41% 192.19% at 87.9% -84.84%, #C0EEFF 2.35%, #FFF 60.49%)' }} className="rounded-[14px] border border-[#E2E2E2] min-h-[55px] py-[6px] xsm:py-[2px] px-[15px] xlx:px-[25px] flex items-center justify-between gap-[15px] mt-[10px] relative z-[1]">
                                        <div className="flex flex-col gap-[2px]">
                                            <p className="text-primary font-extrabold uppercase">{t("passenger.home.first_last_time.peak_hours")}</p>
                                            <div className="flex items-center gap-x-[10px] xsm:flex-row flex-col xsm:ml-0 ml-[-6px]">
                                                <div className="flex items-center gap-[4px]">
                                                    <img src={require('../../../assets/images/sun.png')} alt="sun-img" className="min-w-[17px]" />
                                                    <p className="text-blackDull font-semibold">{lang === "en" ? '8:00 AM to 10:00 AM' : lang === "hi" ? '८:०० सुबह से १०:०० सुबह' : '८:०० सकाळी से १०:०० सकाळी'}</p>
                                                </div>
                                                <div className="flex items-center gap-[4px] xsm:ml-0 ml-[5px]">
                                                    <img src={require('../../../assets/images/moon.png')} alt="moon-img" className="min-w-[17px]" />
                                                    <p className="text-blackDull font-semibold">{lang === "en" ? '05:00 PM to 07:00 PM' : lang === "hi" ? '०५:०० रात्रि से ०७:०० रात्रि' : '०५:०० रात्री ते ०७:०० रात्री'}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <img src={require('../../../assets/images/peak-hour.png')} alt="peak-hour-img" className="min-w-[47px]" />
                                    </div>

                                    <div className="mt-[-45px] w-full flex flex-col items-center justify-center mb-[85px]">
                                        <img src={theme === "dark" ? require("../../../assets/images/dark-metro.gif") : require("../../../assets/images/metro.gif")} alt="metro" className="w-[335px] h-[250px] mix-blend-multiply dark:mix-blend-normal" />

                                        <p className="text-blackDull dark:text-textBlackDull font-semibold max-w-[220px] xlx:max-w-[260px] text-center mt-[-90px]">{lang === "en" ? <>Each metro operates with a time interval of <span className="text-danger dark:text-darkDanger">7 minutes and 5 seconds</span> during peak hours and <span className="text-danger dark:text-darkDanger">10 minutes</span> during non-peak hours.</> : lang === "hi" ? <>प्रत्येक मेट्रो पीक आवर्स के दौरान <span className="text-danger dark:text-darkDanger">७ मिनट ५ सेकंड</span> और नॉन-पीक आवर्स के दौरान <span className="text-danger dark:text-darkDanger">१० मिनट</span> के अंतराल पर चलती है।</> : <>प्रत्येक मेट्रो पीक तासांमध्ये <span className="text-danger dark:text-darkDanger">७ मिनिटे ५ सेकंद</span> आणि नॉन-पीक तासांमध्ये <span className="text-danger dark:text-darkDanger">१० मिनिटांच्या</span> अंतराने चालते.</>}</p>
                                    </div>

                                    <div className="absolute top-0 left-0 right-0 bottom-0 w-full h-full flex items-end justify-end">
                                        <img src={require("../../../assets/images/fare-bg.png")} alt="fare-bg" className="rounded-b-[20px] xlx:rounded-b-[25px] w-full opacity-[0.6] dark:sepia" />
                                    </div>

                                </div>
                            </div>

                        </div>

                    </div >
                </div >}
        </>
    );
};

export default FirstLastTime;